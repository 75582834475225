// Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// Echarts
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart, LineChart } from 'echarts/charts'
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, DataZoomComponent, BrushComponent, ToolboxComponent } from 'echarts/components'
use([CanvasRenderer, PieChart, BarChart, LineChart, GridComponent, TooltipComponent, TitleComponent, LegendComponent, DataZoomComponent, BrushComponent, ToolboxComponent])

export default {
    install(Vue) {
        Vue.use(ElementUI, { size: 'small' })
        Vue.directive('loadMore', {
            inserted(el, binding) {
                const dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap'); // 获取下拉框元素
                dom.addEventListener('scroll', function () {   // 监听元素触底
                    const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (condition) {
                        binding.value();
                    }
                });
            }
        });
        Vue.component('v-chart', ECharts)
    }
}